import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'

import { Container } from '../../components/layout/grid'
import { Markdown } from '../../components/markdown'
import type { FeatureSectionV2Fragment } from '../../graphql/fragments/FeatureSectionV2Fragment.graphql'
import styles from './feature-section-v2.module.scss'

export const FeatureSectionV2: FunctionComponent<Props> = ({
    copyTop,
    copyBottom,
    anchorId = '',
    mediaType = 'image',
    image,
    video,
    disclaimer,
}) => {
    return (
        <Container
            id={anchorId}
            className={`${styles.container} ${
                !copyTop && !copyBottom ? styles.noCopy : ''
            }`}
        >
            <div className={styles.copyContainer}>
                <div className={styles.copyTop}>
                    {!!copyTop && (
                        <div className={styles.heading}>
                            <Markdown
                                tag='div'
                                typeStyle={{
                                    _: 'heading-md',
                                    lg: 'heading-md',
                                }}
                                markdown={copyTop}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.copyBottom}>
                    {!!copyBottom && (
                        <div className={styles.heading}>
                            <Markdown
                                tag='div'
                                typeStyle={{ _: 'heading-md' }}
                                markdown={copyBottom}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div
                className={`${styles.mediaContainer} ${
                    mediaType === 'image' ? styles.image : ''
                }`}
            >
                {image && mediaType === 'image' && (
                    <div>
                        <Image
                            src={image?.url ?? ''}
                            width={image?.width}
                            height={image?.height}
                            alt={image?.alt ?? ''}
                            className={styles.image}
                        />
                    </div>
                )}

                {video && mediaType === 'video' && (
                    <video
                        className={styles.video}
                        autoPlay
                        muted
                        playsInline
                        webkit-playsinline='true'
                        loop={video.loop}
                        poster={video.poster?.source}
                    >
                        <source
                            src={video?.files?.items[0].source}
                            type='video/mp4'
                        />
                    </video>
                )}
            </div>
            {!!disclaimer && (
                <div className={styles.disclaimer}>
                    <Markdown
                        tag='div'
                        typeStyle={{ _: 'body-xs' }}
                        markdown={disclaimer}
                    />
                </div>
            )}
        </Container>
    )
}

export default FeatureSectionV2

type Props = Omit<FeatureSectionV2Fragment, 'sys' | 'type'> & {
    index?: number
}
